import React from "react"
import { graphql, Link } from "gatsby"
import _ from "lodash"
import moment from "moment"
import { Layout, SEO } from "../components"
import { blog } from "../content"
import { GatsbyImage } from "gatsby-plugin-image"

const Blog = ({ data }) => {
  const { metadata } = blog

  const blogPosts = _.orderBy(
    _.map(data.allContentfulBlogPost.edges, "node"),
    "date",
    "desc"
  )

  const renderBlogPost = (blogPost) => {
    return (
      <div className="row" key={blogPost.id}>
        <Link
          className="four columns offset-by-one flex align-center justify-center"
          to={`/blog/${blogPost.slug}`}
        >
          <GatsbyImage
            alt={_.get(blogPost, "headerImage.title")}
            className="header-image"
            image={_.get(blogPost, "headerImage.gatsbyImageData")}
          />
        </Link>
        <div className="six columns">
          <Link to={`/blog/${blogPost.slug}`}>
            <h2>{blogPost.title}</h2>
          </Link>
          <h4>
            {_.get(blogPost, "author[0].name")},{" "}
            {_.get(blogPost, "author[0].title")}
          </h4>
          <h5>{moment(blogPost.date).format("MMM. DD, YYYY")}</h5>
          <p>{blogPost.summary}</p>
        </div>
      </div>
    )
  }

  return (
    <Layout title={metadata.title}>
      <SEO description={metadata.description} title={metadata.title} />
      <section className="blog-section heroless dark">
        <div className="container">
          <h6 className="eleven columns offset-by-one">OUR THOUGHTS</h6>
          {blogPosts.map(renderBlogPost)}
        </div>
      </section>
    </Layout>
  )
}

export default Blog

export const query = graphql`
  query {
    allContentfulBlogPost {
      edges {
        node {
          author {
            name
            title
          }
          date: publishedOn
          headerImage {
            gatsbyImageData(layout: FULL_WIDTH, quality: 90)
            title
          }
          slug
          subtitle
          summary
          title
        }
      }
    }
  }
`
